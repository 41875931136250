import React, {useState, createElement, useEffect} from 'react';
import { FiChevronDown } from 'react-icons/fi';

import menus, { Menu } from '../../../router/menus';
import Str from '../../../helpers/Str';
import { IconBaseProps } from 'react-icons/lib';
import { useNavigate } from 'react-router';
import useAppContext from '../../../hooks/useAppContext';
import Storage from "../../../helpers/Storage";
import {MdDashboard} from 'react-icons/md';
import {FaClipboardCheck, FaUser} from 'react-icons/fa';
import {BsGearFill} from 'react-icons/bs';
export interface AsideMenuProps {
  menuTitle?: string;
}

export default function AsideMenu({
  menuTitle
}: AsideMenuProps) {
  const [loading, setLoading] = useState(true);
  const appContext = useAppContext();
  const menusData = Storage.getUserMenu();
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (menusData) {
      appContext.setMenus(menusData);
    }

    setLoading(false);
  },[]);

  return loading ? (
    <span className="flex items-center justify-center h-7">
      <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </span>
  ):(
    <aside className="flex flex-col justify-between h-screen w-[200px] px-4 py-5 bg-background border-r fixed z-10">
      <ul>
        {appContext.menus ? appContext.menus.map((menu, i) => (<>
          <AsideMenuItem key={`menu--${i}--${menu}`} menu={menu} menuTitle={menuTitle} />
        </>)) : ''}
      </ul>

      <footer className="flex flex-col items-center justify-center">
        <small className="text-secondary">Visão do Cliente</small>
        <small className="text-secondary">Versão 1.01.</small>
      </footer>
    </aside>
  );
}

export interface AsideMenuItemProps {
  menu: Menu;
  menuTitle?: string;
}

export function AsideMenuItem({
  menu,
  menuTitle
}: AsideMenuItemProps) {

  const [isOpened, setIsOpened] = useState(!!(menuTitle === menu.title || menu.subMenus?.find(sm => sm.title === menuTitle)));
  const navigate = useNavigate();

  const Icon = (props: IconBaseProps) => {
    let icon: any = null;
    switch (menu.title) {
    case 'Dashboards':
      icon = MdDashboard;
      break;
    case 'Cadastros':
      icon = FaClipboardCheck;
      break;
    case 'Usuários':
      icon = FaUser;
      break;
    case 'Manutenções':
      icon = BsGearFill;
      break;

    }

    if (! icon) return <></>;
    return createElement(icon, props);
  };

  function handleMainClick() {
    menu.path && navigate(menu.path);
    setIsOpened(!isOpened);
  }

  return (
    <li>
      <div onClick={handleMainClick} className={Str.tw(
        'flex items-center justify-between px-4 py-3 cursor-pointer rounded-md',
        'transition hover:bg-light hover:text-primary',
        menuTitle === menu.title ? 'bg-light-dark font-bold' : '' 
      )}>
        <div className="flex items-center gap-2">
          <Icon />
          {menu.title}
        </div>

        {!!menu.subMenus?.length && <FiChevronDown className={isOpened ? 'rotate-180' : ''} />}
      </div>

      <div className="pl-4" hidden={!isOpened}>
        {menu.subMenus?.map((subMenu, i) => (
          <div key={`subMenu--${subMenu.path}--${i}`} onClick={() => subMenu.path && navigate(subMenu.path)} className={Str.tw(
            'flex items-center justify-between px-4 py-3 cursor-pointer rounded-md',
            'transition hover:bg-light hover:text-primary',
            menuTitle === subMenu.title ? 'pointer-events-none bg-light-dark font-bold' : '' 
          )}>
            {subMenu.title}
          </div>
        ))}
      </div>
    </li>
  );
}